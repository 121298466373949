import React from "react"
import PropTypes from "prop-types"

class ChatBotReview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user_name: "",
      user_about: "",
      chosen_service: "",
      project_about: "",
      project_deadline: "",
      project_budget: "",
      user_contact: "",
    }
  }

  componentWillMount() {
    const { steps } = this.props
    const {
      user_name,
      user_about,
      chosen_service,
      project_about,
      project_deadline,
      project_budget,
      user_contact,
    } = steps

    this.setState({
      user_name,
      user_about,
      chosen_service,
      project_about,
      project_deadline,
      project_budget,
      user_contact,
    })
  }

  render() {
    const {
      user_name,
      user_about,
      chosen_service,
      project_about,
      project_deadline,
      project_budget,
      user_contact,
    } = this.state
    return (
      <div style={{ width: "100%" }}>
        <h3>Summary</h3>
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{user_name.value}</td>
            </tr>
            <tr>
              <td>About you</td>
              <td>{user_about.value}</td>
            </tr>
            <tr>
              <td>Contacts</td>
              <td>{user_contact.value}</td>
            </tr>
            <tr>
              <td>Chosen service</td>
              <td>{chosen_service.value}</td>
            </tr>
            <tr>
              <td>Project</td>
              <td>{project_about.value}</td>
            </tr>
            <tr>
              <td>Deadline/ Budget</td>
              <td>
                {project_deadline.value}/{" "}
                {project_budget.value === "Talk Later" ||
                project_budget.value === "No Limit"
                  ? "-"
                  : project_budget.value}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

ChatBotReview.propTypes = {
  steps: PropTypes.object,
}

ChatBotReview.defaultProps = {
  steps: undefined,
}

export default ChatBotReview
