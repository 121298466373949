import React from "react"
import { useSiteMetadata } from "../../hooks/use-site-metadata"
import MainContentWrap from "../Wrappers/MainContentWrap"
import BackgroundImgContactCard from "../SingleComponents/BackgroundImgContactCard"
import arrow from "../../images/icons/utils/arrow.svg"
import styled from "styled-components"
import values from "../../utils/values"
import {  useTranslation } from "gatsby-plugin-react-i18next"

const ContactUsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;

  .contactUs-description {
    margin-bottom: 2rem;
  }

  .contact-card {
    width: 100%;
    max-width: 300px;
    padding: 2px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    .contact-card-text {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;

      ul.social {
        margin-bottom: 0;
      }

      li {
        width: fit-content;
        position: relative;

        .underline:before {
          content: "";
          display: block;
          background: currentColor;
          position: absolute;
          width: 0;
          height: 1px;
          bottom: -5px;
          right: 0;
          -webkit-transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1);
          -moz-transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1);
          -o-transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1);
          transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        }

        .underline:hover:before {
          width: 100%;
        }

        .underline:active {
          width: 100%;
        }
      }

      .social {
        display: flex;
        flex-wrap: wrap;

        li:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }

  @media (min-width: 540px) {
    .contact-card {
      max-width: 400px;

      .contact-card-text {
        padding: 20px 30px;
      }
    }
  }

  @media (min-width: ${values.DEVICESIZE.tablet}) {
      .contact-card-text {
        padding: 20px 40px;
      }
    }
  }

  @media (min-width: ${values.DEVICESIZE.laptop}) {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;

    .contactUs-description {
      margin-bottom: 0;
      margin-right: 2.5rem;
    }
    .contact-card {
      max-width: 420px;

      .contact-card-text {
        padding: 15px 40px;
      }
    }
  }

  @media (min-width: ${values.DEVICESIZE.laptopL}) {
   .contactUs-description {
      margin-right: 0;
    }
  
    .contact-card {
      max-width: 540px;

      .contact-card-text {
        padding: 40px 30px;
      }
    }
  }
  
    @media (min-width: ${values.DEVICESIZE.desktop}) {
    .contact-card {
      max-width: 700px;

      .contact-card-text {
        padding: 90px 55px;
      }
    }
  }
`

export default function ContactUs() {
const {t} = useTranslation()

  const {
    email,
    phone1,
    phone3,
    facebook,
    linkedin,
    instagram,
    twitter,
  } = useSiteMetadata()

  return (
    <MainContentWrap>
      <ContactUsWrap>
        <div className="contactUs-description">
          <h1 className="fadeup">
            {t("Let's get in touch.")}
            <br /> {t("Contact us.")}
          </h1>
          <div className="fadeup fadeup-2 sub">
            {t("If you have any questions, requests, or inquiries for us,")}
            <br /> {t("just ask. We want to hear them.")}
          </div>
          <a
            href={`mailto:${email}?subject=Questions/request to web-boxes.com`}
            target="_blank"
            rel="noopener noreferrer"
            className="arrowlink fadeup fadeup-3"
          >
            {t("CONTACT NOW")}
            <img src={arrow} alt="arrow" />
          </a>
        </div>

        <div className="contact-card">
          <BackgroundImgContactCard>
            <div className="contact-card-text">
              <div className="listing fadeup fadeup-6">
                <div className="title">EMAIL</div>
                <ul className="body list-unstyled">
                  <li>
                    <a
                      className="underline"
                      href={`mailto:${email}?subject=Questions/request to web-boxes.com`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {email}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="listing fadeup fadeup-7">
                <div className="title">PHONE</div>
                <ul className="body list-unstyled">
                  <li>
                    <a className="underline" href={`tel:${phone1}`}>
                      {phone1}
                    </a>
                  </li>
                  <li>
                    <a className="underline" href={`tel:${phone3}`}>
                      {phone3}
                      {console.log(phone3)}
                  
                    </a>
                  </li>
                </ul>
              </div>
              <div className="listing fadeup fadeup-8">
                <div className="title">SOCIAL</div>
                <ul className="social list-inline">
                  <li>
                    <a
                      href={linkedin}
                      className="underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href={facebook}
                      className="underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href={instagram}
                      className="underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href={twitter}
                      className="underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </BackgroundImgContactCard>
        </div>
      </ContactUsWrap>
    </MainContentWrap>
  )
}
