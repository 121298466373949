import React, { useState } from "react"
import MainContentWrap from "../Wrappers/MainContentWrap"
import Modal from "../SingleComponents/Modal"
import ContactChatBot from "./ContactChatBot"
import arrow from "../../images/icons/utils/arrow.svg"
// import closeBtn from "../images/icons/utils/close-butt.svg"
import AlexFoto from "../SingleComponents/AlexFoto"
import "./ContactHireUs.css"
import {  useTranslation } from "gatsby-plugin-react-i18next"

export default function ContactHireUs() {
  const {t} = useTranslation()
  const [showChatBot, setShowChatBot] = useState(false)

  const onShowChatBot = ({ target }) => {
    if (!target.matches("#ModalWindow") && showChatBot) {
      return
    }
    setShowChatBot(!showChatBot)
  }

  return (
    <>
      <MainContentWrap>
        <div className="contactHireUs-wrap">
          <div className="col-wrapper col-xs-5 col-sm-7 col-md-6 col-lg-4">
            <h2 className="fadeup">
            {t("Let's make things.")}
              <br />
              {t("Hire us.")}
            </h2>
            <div className="fadeup fadeup-2 sub">
              {t("If you have an idea, a project, or a work that require our services,")}
              <br />
              {t("let's talk. We want to know more.")}
            </div>
            <button
              type="button"
              className="arrowlink fadeup fadeup-3"
              onClick={onShowChatBot}
            >
              {/*TALK NOW*/}
              {t("MAKE INQUIRY NOW")}
              <img src={arrow} alt="arrow" />
            </button>
          </div>
          <div className="col-wrapper col-xs-5 col-sm-4 col-md-4 col-lg-5">
            <div className="stevehodor visible-xs"></div>
          </div>
          <div className="alex-foto-wrap">
            <AlexFoto />
          </div>
        </div>
      </MainContentWrap>
      {showChatBot && (
        <div onClick={onShowChatBot}>
          <Modal>
            <ContactChatBot />
          </Modal>
        </div>
      )}
    </>
  )
}
