import React from "react"
import { ThemeProvider } from "styled-components"
import ChatBot from "react-simple-chatbot"
import ChatBotReview from "../SingleComponents/ChatBotReview"
import AlexAvatar from "../../images/wbx-team-foto/alex/alex-chat.jpg"
import UserAvatar from "../../images/icons/utils/user-avatar.svg"
import sendChatbotForm from "../../utils/functions/emailJS-sender"
import values from "../../utils/values"
import "./ContactChatBot.css"

const chatbotTheme = {
  background: "#f5f8fb",
  // fontFamily: "Helvetica Neue",
  // headerFontSize: "15px",
  headerBgColor: "inherit",
  headerFontColor: values.COLORS.lightTextColor,
  botBubbleColor: "#282828",
  botFontColor: values.COLORS.lightTextColor,
  userBubbleColor: values.COLORS.secondLightBackgroundColor,
  userFontColor: values.COLORS.darkTextColor,
}

const Message8 = ({ ...props }) => {
  return (
    <div style={{ textAlign: `left` }}>
      <p>
        Thank you.
        <br />
        So {props.steps.user_name.value}, what services do you need?
      </p>
    </div>
  )
}
const Message19 = ({ ...props }) => {
  return (
    <div style={{ textAlign: `left` }}>
      <p>
        Oh, I'm almost forgot.
        <br />
        More importantly, how can I reach you, {props.steps.user_name.value}?
      </p>
    </div>
  )
}

const chatbotSteps = [
  {
    id: "1",
    component: (
      <div style={{ textAlign: `left` }}>
        <p>
          Hello there, I'm Alex.
          <br />
          Thank you for your interest in our services.
        </p>
      </div>
    ),
    asMessage: true,
    trigger: "2",
  },
  {
    id: "2",
    message: "What's your name?",
    trigger: "user_name",
  },
  {
    id: "user_name",
    placeholder: "write your name ...",
    user: true,
    validator: value => {
      if (value.length === 1) {
        return "value should be more than 1 letter"
      }
      return true
    },
    trigger: "4",
  },
  {
    id: "4",
    message: "Nice to meet you, {previousValue}.",
    trigger: "5",
  },
  {
    id: "5",
    component: (
      <div style={{ textAlign: `left` }}>
        <p>
          I have a few questions for you that will help us understand your
          project.
          <br />
          Let's start about you and your business or your work.
        </p>
      </div>
    ),
    asMessage: true,
    // delay: 2000,
    trigger: "6",
  },
  {
    id: "6",
    message: "Please tell me a few things on that.",
    trigger: "user_about",
  },
  {
    id: "user_about",
    placeholder: "Write about YOUR SELF / BUSINESS / WORK ...",
    user: true,
    trigger: "8",
  },
  {
    id: "8",
    component: <Message8 />,
    asMessage: true,
    trigger: "chosen_service",
  },
  {
    id: "chosen_service",
    placeholder: "CHOOSE OUR SERVICES",
    options: [
      { value: "Website", label: "Website", trigger: "10" },
      { value: "E-Commerce", label: "E-Commerce", trigger: "10" },
      { value: "Mobile App", label: "Mobile App", trigger: "10" },
      { value: "Branding", label: "Branding", trigger: "10" },
      { value: "Print Design", label: "Print Design", trigger: "10" },
      { value: "Custom", label: "Custom", trigger: "10" },
    ],
  },
  {
    id: "10",
    message: "Great! Please tell me about the project.",
    trigger: "project_about",
  },
  {
    id: "project_about",
    placeholder: "WRITE ABOUT YOUR PROJECT ...",
    user: true,
    trigger: "12",
  },
  {
    id: "12",
    component: (
      <div style={{ textAlign: `left` }}>
        <p>
          Thank you. We're almost done!
          <br />
          Do you have any deadline for the project?
        </p>
      </div>
    ),
    asMessage: true,
    trigger: "project_deadline",
  },
  {
    id: "project_deadline",
    placeholder: "CHOOSE YOUR PROJECT DEADLINE",
    options: [
      { value: "1 Month", label: "1 Month", trigger: "14" },
      { value: "2 Month", label: "2 Month", trigger: "14" },
      { value: "3 Month", label: "3 Month", trigger: "14" },
      { value: "6+ Month", label: "6+ Month", trigger: "14" },
      { value: "1+ Year", label: "1+ Year", trigger: "14" },
      { value: "No Rush", label: "No Rush", trigger: "14" },
    ],
  },
  {
    id: "14",
    component: (
      <div style={{ textAlign: `left` }}>
        <p>
          OK. Last question.
          <br />
          What's the budget limit on this project?
        </p>
      </div>
    ),
    asMessage: true,
    trigger: "project_budget",
  },
  {
    id: "project_budget",
    placeholder: "CHOOSE YOUR PROJECT BUDGET",
    options: [
      {
        value: "IDR 20+ Million",
        label: "IDR 20+ Million",
        trigger: "16",
      },
      {
        value: "IDR 50+ Million",
        label: "IDR 50+ Million",
        trigger: "16",
      },
      {
        value: "IDR 100+ Million",
        label: "IDR 100+ Million",
        trigger: "16",
      },
      { value: "No Limit", label: "No Limit", trigger: "17" },
      { value: "Talk Later", label: "Talk Later", trigger: "18" },
    ],
  },
  {
    id: "16",
    message: "Great!",
    trigger: "19",
  },
  {
    id: "17",
    message: "Wow, don't have any budget limit! impressive!",
    trigger: "19",
  },
  {
    id: "18",
    message: "Sure! We can talk more later.",
    trigger: "19",
  },
  {
    id: "19",
    component: <Message19 />,
    asMessage: true,
    trigger: "user_contact",
  },
  {
    id: "user_contact",
    placeholder: "WRITE YOUR EMAIL ADDRESS OR PHONE NUMBER...",
    user: true,
    trigger: "23",
  },
  {
    id: "23",
    message: "Great! Check out your summary",
    trigger: "review",
  },
  {
    id: "review",
    component: <ChatBotReview />,
    asMessage: true,
    trigger: "update",
  },
  {
    id: "update",
    message: "Would you like to update some field?",
    trigger: "update-question",
  },
  {
    id: "update-question",
    options: [
      { value: "yes", label: "Yes", trigger: "update-yes" },
      { value: "no", label: "No", trigger: "end" },
    ],
  },
  {
    id: "update-yes",
    message: "What field would you like to update?",
    trigger: "update-fields",
  },
  {
    id: "update-fields",
    options: [
      {
        value: "user_name",
        label: "name",
        trigger: "update-user_name",
      },
      {
        value: "user_about",
        label: "about you",
        trigger: "update-user_about",
      },
      {
        value: "user_contact",
        label: "contacts",
        trigger: "update-user_contact",
      },
      {
        value: "chosen_service",
        label: "chosen service",
        trigger: "update-chosen_service",
      },
      {
        value: "project_about",
        label: "about project",
        trigger: "update-project_about",
      },
      {
        value: "project_deadline",
        label: "project deadline",
        trigger: "update-project_deadline",
      },
      {
        value: "project_budget",
        label: "project budget",
        trigger: "update-project_budget",
      },
    ],
  },
  {
    id: "update-user_name",
    update: "user_name",
    trigger: "23",
  },
  {
    id: "update-user_about",
    update: "user_about",
    trigger: "23",
  },
  {
    id: "update-chosen_service",
    update: "chosen_service",
    trigger: "23",
  },
  {
    id: "update-project_about",
    update: "project_about",
    trigger: "23",
  },
  {
    id: "update-project_deadline",
    update: "project_deadline",
    trigger: "23",
  },
  {
    id: "update-project_budget",
    update: "project_budget",
    trigger: "23",
  },
  {
    id: "update-user_contact",
    update: "user_contact",
    trigger: "23",
  },
  {
    id: "end",
    message:
      "Thank you! Your data was submitted successfully. We'll get you back soon",
    end: true,
  },
]

class ContactChatBot extends React.Component {
  componentDidMount() {
    this.handleEnd = this.handleEnd.bind(this)
  }

  handleEnd({ steps }) {
    const {
      user_name,
      user_about,
      chosen_service,
      project_about,
      project_deadline,
      project_budget,
      user_contact,
    } = steps

    // const message = `Message from web-boxes.com/chatbot:
    // Client name: ${user_name.value},
    // About client: ${user_about.value},
    // Contacts: ${user_contact.value},
    // About project:
    //      service - ${chosen_service.value},
    //      budget - ${project_budget.value},
    //      deadline - ${project_deadline.value},
    //      other inf - ${project_about.value}.`

    const message = {
      user_name: user_name.value,
      user_about: user_about.value,
      chosen_service: chosen_service.value,
      project_about: project_about.value,
      project_deadline: project_deadline.value,
      project_budget: project_budget.value,
      user_contact: user_contact.value,
    }
    // console.log(message)
    sendChatbotForm(message)
  }

  render() {
    return (
      <div id="chatbox">
        <ThemeProvider theme={chatbotTheme}>
          <ChatBot
            handleEnd={this.handleEnd}
            steps={chatbotSteps}
            headerTitle="Tell us more about your project"
            botDelay={1500}
            botAvatar={AlexAvatar}
            userAvatar={UserAvatar}
            className="chatbot-wrap"
            style={{
              width: `100%`,
              backgroundColor: values.COLORS.darkBackgroundColor,
            }}
            contentStyle={{ height: `75%` }}
            bubbleStyle={{ fontSize: `1.3rem` }}
            inputStyle={{
              fontSize: `1.3rem`,
              paddingLeft: `1rem`,
            }}
          />
        </ThemeProvider>
      </div>
    )
  }
}

export default ContactChatBot
