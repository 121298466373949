import React from "react"
import { graphql } from "gatsby"

import ColorThemeWrapper from "../components/Wrappers/ColorThemeWrapper"
import SEO from "../components/seo"
import ContactUs from "../components/Contacts/ContactUs"
import ContactHireUs from "../components/Contacts/ContactHireUs"
import ContactJoinUs from "../components/Contacts/ContactJoinUs"

const Contacts = () => (
  <>
    <SEO title="Contacts" />
    <ColorThemeWrapper
      wrap1={<ContactUs />}
      wrap2={<ContactHireUs />}
      wrap3={<ContactJoinUs />}
    />
  </>
)

export default Contacts

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "contacts"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
