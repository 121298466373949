import React from "react"
import MainContentWrap from "../Wrappers/MainContentWrap"
import { useSiteMetadata } from "../../hooks/use-site-metadata"
import arrowWhite from "../../images/icons/utils/arrowWhite.svg"
import "./ContactJoinUs.css"
import {  useTranslation } from "gatsby-plugin-react-i18next"

export default function ContactJoinUs() {
  const { email } = useSiteMetadata()
  const {t} = useTranslation()
  
  return (
    <MainContentWrap>
      <div className="contactJoinUs-wrap">
        <div className="col-wrapper col-xs-5 col-sm-7 col-md-6 col-lg-4">
          <h2 className="fadeup">
            {t("Let's work together.")}
            <br />
            {t("Join us.")}
          </h2>
          <div className="fadeup fadeup-2 sub">
         {t("If you have the talent, the passion, and the desire to make good things, please apply. We want to meet you.")}
          </div>
          <a
            href={`mailto:${email}?subject=Job candidate`}
            className="arrowlink fadeup fadeup-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("APPLY NOW")}
            <img src={arrowWhite} alt="arrow" />
          </a>
        </div>
        <div className="open-position col-wrapper col-xs-5 col-sm-4 col-md-4 col-lg-5">
          <div className="listing fadeup fadeup-4">
            <div className="title">{t("OPEN POSITION")}</div>
            <ul className="body list-unstyled">
              <li>Web Developer</li>
              <li>Web Designer</li>
              <li>Android Developer</li>
              <li>iOS Developer</li>
            </ul>
          </div>
        </div>
      </div>
    </MainContentWrap>
  )
}
